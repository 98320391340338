import React from 'react';
import { Urban } from '../components/backgrounds';
import {
  SEO, Layout, RWLogo, Grid, Nav, LinkButton, Card,
} from '../components';
import '../index.css';

const delay = 0;
const MenuPage = () => (
  <Layout>
    <SEO title="Home" keywords={['gatsby', 'application', 'react']} />
    <Urban />
    <Nav show />
    <Grid>
      <Card color="rgba(169, 188, 248, 0.93)" fontColor="#222" title="Modernize" delay={delay}>
        Bring your business into the present with current web technologies. Build your brand with
        blazing fast interactive applications.
      </Card>
      <Card color="rgba(62, 69, 179, 0.93)" fontColor="#CCC" title="Engage" delay={delay + 200}>
        Attract more customers and increase your conversion rate. Proactively galvanize your
        security posture to protect your business.
      </Card>
      <Card color="rgba(228, 196, 16, 0.93)" fontColor="#222" title="Integrate" delay={delay + 400}>
        Keep your existing content management system or point of sale. Integrate multiple platforms
        with a consistent design paradigm.
      </Card>
      <Card
        color="rgba(161, 76, 99, 0.93)"
        fontColor="#CCC"
        title="Meet Rick"
        delay={delay + 600}
        arrow
      >
        I am Rick Livernois, full stack web developer in Memphis, Tenessee.
        <br />
        <LinkButton to="/contact">I can work for you.</LinkButton>
      </Card>
    </Grid>
    <RWLogo />
  </Layout>
);

export default MenuPage;
